import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {

    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading, user } = useAuth0();

    useEffect(() => {
        if (isLoading) return; // Wait for Auth0 to finish loading
        const authenticateAndStoreToken = async () => {
            if (!isAuthenticated) {
                await loginWithRedirect();
            } else {
                const token = await getAccessTokenSilently();
                sessionStorage.setItem('token', token);
            }
        };

        authenticateAndStoreToken();
    }, [isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading]);

    return (
        <div>
            {isAuthenticated ? (
                <h2>Welcome, {user?.name}</h2>
            ) : (
                <button onClick={() => loginWithRedirect()}>Log in</button>
            )}
        </div>
    );
};

export default LoginButton;