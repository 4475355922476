import axios from 'axios';

const createApiClient = (baseURL, getAccessTokenSilently) => {
    const client = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    // Add a request interceptor to include the token
    client.interceptors.request.use(
        async (config) => {
            try {
                const token = await getAccessTokenSilently();
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            } catch (error) {
                console.error('Error fetching access token:', error);
                throw error;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    return client;
};

export default createApiClient;
