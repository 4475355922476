import React from 'react';
import ToggleButton from '../elements/ToggleButton';
import EditableButton from '../EditableButton';

const TagsComponent = (props) => {

  ;
  const handleButtonToggle = (value) => {
    props.handleButtonToggle(value);
  };

  const handleEditableButtonUpdate = (value) => {
    const newTag = {
      "names": [value],
      "variant": "personal",
      "color": "green"
    };
    props.handleEditableButtonUpdate(newTag);
  };

  if (!props.tags) {
    return (<div>Loading tags</div>)
  }

  return (
    <div>
      <div className="flex flex-wrap">
        {props.tags.map((tag, index) => (
          <ToggleButton
            key={tag.tagId}
            tagId={tag.tagId}
            name={tag.name}
            color='blue'
            isToggled={tag.active}
            handleButtonToggle={handleButtonToggle}
          />
        ))
        }
        {props.includeEditButton ? <EditableButton text="+" onUpdate={handleEditableButtonUpdate} /> : null}
      </div>
    </div>
  );
};

export default TagsComponent;