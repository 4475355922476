import React, { useEffect, useState } from 'react';
import CheckmarkTable from '../elements/CheckmarkTable';
import Util from '../Util';
import { getUserHabits } from '../api';
import { useApiClient } from '../../service/useApiClient';


const SchedulePage = (props) => {
    const api = useApiClient();

    const [habits, setHabits] = useState([]);

    useEffect(() => {
        async function getTaskList() {
            await getUserHabits(api).then(habits => {
                setHabits(habits);
            })
        }

        getTaskList();
        setHabits(habits);
    }, []);

    return (
        <div>
            <div className="text-center text-xl">Habit Schedule</div>
            <div className="flex flex-row">
                <CheckmarkTable tableName='schedule' habits={habits} />
            </div>
        </div>
    );
};

export default SchedulePage;
