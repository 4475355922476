import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../../output.css'
import MenuStyles from './MenuStyles';
import Util from '../../Util';

const MenuItem = ({ to, children }) => (
  <NavLink
    to={to}
    style={({ isActive }) => (isActive ? MenuStyles.activeMenuItem : {})}
    className="bg-blue-500 hover:bg-blue-700 text-white rounded-t-lg font-bold py-2 px-4 mr-1 inline-flex items-center"
  >
    {children}
  </NavLink>
);

const Navigation = () => {
  return (
    <div>

      <h1 className="text-center text-xl">{Util.getCurrentDate()}</h1>
      <nav className="">
        <MenuItem to="/dashboard">Home</MenuItem>
        <MenuItem to="/write">Write</MenuItem>
        <MenuItem to="/read">Read</MenuItem>
        <MenuItem to="/track">Track</MenuItem>
        <MenuItem to="/admin">Admin</MenuItem>
      </nav>
    </div>
  );
}

export default Navigation;