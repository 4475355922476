import React, { useEffect, useState } from 'react';
import './elements.css';
import EntryList from './EntryList';
const ScrollDetectorObject = (props) => {
    const { journal } = props;

    const [nextElementIndex, setNextElementIndex] = useState(1);
    const [elements, setElements] = useState<HTMLCollectionOf<Element>>(document.getElementsByClassName('entryDate'));
    const [currentFloatingElement, setCurrentFloatingElement] = useState<Element>();
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);
    const [noteContent, setNoteContent] = useState();

    useEffect(() => {
        setCurrentFloatingElement(elements[0]);
    }, [elements, noteContent]);

    useEffect(() => {
        // const outputElement = document.getElementById('output');
        // outputElement.innerHTML = journal;
    }, [journal]);

    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('container');
            if (container !== undefined) {
                const containerRect = container.getBoundingClientRect();
                debugger;
                if (elements[nextElementIndex] === undefined) {
                    setCurrentFloatingElement(elements[0]);
                    setNextElementIndex(1);
                }

                if (elements[nextElementIndex] !== undefined) {
                    const nextElementRect = elements[nextElementIndex].getBoundingClientRect();
                    const currentElementRect = currentFloatingElement.getBoundingClientRect();

                    setScrollPosition(window.scrollY);
                    const isAtTopOfContainer = containerRect.top >= nextElementRect.top;

                    if (isAtTopOfContainer) {
                        setCurrentFloatingElement(elements[nextElementIndex]);
                        if (nextElementIndex < elements.length - 1) {
                            setNextElementIndex(nextElementIndex + 1);
                        }
                    } else {
                        const currentElementIsAtTop = containerRect.top >= currentElementRect.top;
                        if (!currentElementIsAtTop && nextElementIndex >= 1) {
                            setCurrentFloatingElement(elements[nextElementIndex - 1]);
                            setNextElementIndex(nextElementIndex - 1);
                        }
                    }
                }
            }

        };
        const scrollableDiv = document.getElementById('container');
        scrollableDiv.addEventListener('scroll', handleScroll);

        return () => {
            scrollableDiv.removeEventListener('scroll', handleScroll);
        };
    }, [currentFloatingElement, nextElementIndex]);

    return (
        <div id="container">
            <div className="floating-element">
                {currentFloatingElement ? currentFloatingElement.textContent : ""}
            </div>

            <div id="output">
                <EntryList entryList={journal} />
            </div>
        </div >
    );
}

export default ScrollDetectorObject;