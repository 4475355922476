import moment from 'moment';

export const config = {
    apiUrl: process.env.REACT_APP_API_URL || '',
    taskQueryKey: ['tasks', 'personal'],
    habitsQueryKey: ['habits', 'personal'],
    tagQueryKey: ['tags', 'personal'],
    notesQueryKey: ['notes', 'personal']
};

const toggleTag = (setStateFunc, tag) => {
    let newTags;
    setStateFunc((prevState) => {
        // Compute the new value of counter based on the previous state
        if (prevState.includes(tag)) {
            newTags = prevState.filter(item => item !== tag);
        } else {
            newTags = [...prevState];
            newTags.push(tag);
        }
        return newTags;
    });
}

const generateTaskId = (task) => {
    return task.replace(/\s+/g, "").toLowerCase();
}

const getCurrentDate = () => {
    var date = moment();
    return date.format('MMMM D, YYYY');
}

const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
]

const getDayOfWeek = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const currentDayName = daysOfWeek[dayOfWeek];
    return currentDayName;
}

var exports = {
    // fetchTags,
    toggleTag,
    generateTaskId,
    getDayOfWeek,
    // getUserHabits,
    getCurrentDate,
    config
};

export default exports;