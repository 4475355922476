import { useState, useEffect } from "react";
import './elements.css'; // Import CSS file for button styles
import React from "react";

const ToggleButton = (props) => {
    const [isClicked, setIsClicked] = useState(false);


    useEffect(() => {
        setIsClicked(props.isToggled);
    }, [props.isToggled]);

    const handleChildButtonClick = () => {
        props.handleButtonToggle(props.tagId);
        setIsClicked(!isClicked);
    };

    const className = `button ${isClicked ? 'on' : ''} bg-${props.color}-500 text-white font-bold text-sm py-2 px-4 items-center rounded-full flex-item`

    return (
        <div className="flex">
            <button
                className={className}
                autoFocus
                onClick={handleChildButtonClick}>
                {props.name}
            </button>
        </div>
    );
}

export default ToggleButton;