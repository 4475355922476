import { NavLink, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import MenuStyles from './MenuStyles';

const SubMenu = () => {
    const [menuItems, setMenuItems] = useState();
    const location = useLocation();
    const submenuItems = [
        { parentPath: '/admin', path: 'admin/habits', label: 'Habits' },
        { parentPath: '/admin', path: 'admin/schedule', label: 'Habits Schedule' },
        { parentPath: '/admin', path: 'admin/tags', label: 'Tags' },
    ];

    useEffect(() => {
        let activeItems = submenuItems.filter(function (item) {
            if (location.pathname.includes(item.parentPath)) {
                return true;
            }
        });
        setMenuItems(activeItems);
    }, [useLocation()]);

    return (
        <div className="submenu">
            {/* Render submenu items based on current route */}
            {menuItems && menuItems.map((item) => (
                <NavLink style={({ isActive }) => {
                    return isActive ? MenuStyles.activeSubMenuItem : {};
                }} key={item.path} to={item.path} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 inline-flex items-center">
                    {item.label}
                </NavLink>
            ))
            }
        </div >
    );
};


export default SubMenu;