export const getUserHabits = async (api) => {
    try {
        var result = await api.get('/habits', {
            params: {
                variant: "personal"
            }
        });
        return result.data;
    } catch (error) {
        console.error('Error retrieving data from file:', error);
        alert('Error retrieving data from file: ' + error);
    }
    return "";
};

export const fetchTags = async (api) => {
    const response = await api.get('/tags', {
        params: {
            variant: "personal"
        }
    });
    return response.data.map((tag) => ({ ...tag, active: false }));
};

export const createHabit = async (api, newItem) => {
    debugger;
    const response = await api.post('/habits', {
        "name": newItem,
        "variant": "personal",
        "description": "",
        "tags": ["238f58dd-bd2e-4a4e-8ea1-8bc60883d145"]
    });
    return response;
};


export const completeTasks = async (api, taskIds) => {
    debugger;
    await api.patch('/tasks', { variant: "personal", taskIds: taskIds, newStatus: 'complete' });
};

export const activateTasks = async (api, taskIds) => {
    debugger;
    await api.patch('/tasks', { variant: "personal", taskIds: taskIds, newStatus: 'active' });
};

export const updateHabitStatuses = async (api, habitIds) => {
    debugger;
    await api.patch('/habits', { variant: "personal", completedHabitIds: habitIds });
};

export const getTasks = async (api) => {
    const response = await api.get('/tasks', {
        params: {
            variant: "personal"
        }
    });
    return response;
};


export const getActiveHabits = async (api) => {
    const response = await api.get('/schedules/today', {
        params: {
            variant: "personal"
        }
    });
    return response;
};


export const deleteHabit = async (api, habitId) => {
    const response = await api.delete('/habits', {
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            "variant": "personal",
            "HabitId": habitId
        }
    });
    return response;
};

export const updateHabitName = async (api, habitId, name) => {
    const response = await api.put(`/api/items/${habitId}`, { item: name });
    debugger;
    return response;
};

export const getHabitsSchedule = async (api) => {
    const response = await api.get('/schedules?variant=personal');
    return response;
};

export const updateUserSchedule = async (api, payload) => {
    await api.patch('/schedules', payload);
}

export const updateNoteDraft = async (api, content) => {
    await api.post('/notes/draft', content);
}

export const createTasks = async (api, taskNames, selectedTagIds, status) => {
    await api.post('/tasks', { tasks: taskNames, tags: selectedTagIds, status: status, variant: 'personal' });
}


export const createNote = async (api, content, selectedTagIds) => {
    await api.post('/notes', { content: content, tagIds: selectedTagIds, variant: "personal" });
}


export const getNotes = async (api) => {
    return await api.get('/notes', {
        params: {
            variant: "personal"
        }
    });
}

export const createTag = async (api, tagObject) => {
    await api.post('/tags', tagObject);
}

export const deleteTags = async (api, tagIdsToDelete) => {
    await api.delete('/tags', {
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            "variant": "personal",
            "tagIds": tagIdsToDelete
        }
    });
}

export const createUser = async (api) => {
    await api.post('/users', { variant: "personal" });
}

export const getUser = async (api) => {
    return await api.get('/users', {
        params: {
            variant: "personal"
        }
    });
}