// DefaultComponent.js
import React from 'react';
import TagsComponent from './TagsComponent';
import { toggleTagActive } from '../../slices/tagsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store';

const ReadTagsComponent = (props) => {
    const dispatch = useDispatch();
    const tags = useSelector((state: RootState) => state.tags.items);

    const handleButtonToggle = (value) => {
        dispatch(toggleTagActive(value));
    };

    return (
        <div>
            <TagsComponent tags={tags} handleButtonToggle={handleButtonToggle} includeEditButton={false} />
        </div>
    );
}

export default ReadTagsComponent;