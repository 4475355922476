import React from 'react';
import '../elements/elements.css';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page H',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page I',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page J',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page K',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page L',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page M',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page N',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page O',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page P',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page Q',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page R',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page S',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page T',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page U',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const tasks = [
  {
    name: "Write Report",
    schedule: [
      { day: "Mon", isComplete: true },
      { day: "Tue", isComplete: true },
      { day: "Fri", isComplete: false }
    ]
  },
  {
    name: "Team Meeting",
    schedule: [
      { day: "Wed", isComplete: false }
    ]
  },
  {
    name: "Code Review",
    schedule: [
      { day: "Mon", isComplete: true },
      { day: "Thu", isComplete: true }
    ]
  },
  {
    name: "Design Workshop",
    schedule: [
      { day: "Tue", isComplete: false },
      { day: "Thu", isComplete: false }
    ]
  },
  {
    name: "Client Call",
    schedule: [
      { day: "Fri", isComplete: true }
    ]
  }
];


const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function ScheduleTable() {
  function isDayComplete(day) {
    return tasks.every(task => {
      const scheduleItem = task.schedule.find(s => s.day === day);
      return scheduleItem ? scheduleItem.isComplete : true;
    });
  }

  return (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr>
          <th style={{ textAlign: "left", padding: "8px" }}>Task</th>
          {daysOfWeek.map(day => (
            <th key={day} style={{ padding: "8px", textAlign: "center" }}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tasks.map(task => (
          <tr key={task.name}>
            <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{task.name}</td>
            {daysOfWeek.map(day => {
              const scheduleItem = task.schedule.find(s => s.day === day);
              return (
                <td key={day} style={{ padding: "8px", textAlign: "center", borderBottom: "1px solid #ddd" }}>
                  {scheduleItem ? (
                    <span
                      style={{
                        display: "inline-block",
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: scheduleItem.isComplete ? "#54c45e" : "#D3D3D3",  // Light Kelly Green if complete
                        border: scheduleItem.isComplete ? "1px solid black" : "1px solid #AAA",
                        // boxShadow: scheduleItem.isComplete ? "0px 0px 5px #5A9216" : "0px 0px 5px #AAA",
                        transition: "background-color 0.3s ease"
                      }}
                    ></span>
                  ) : null}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          {daysOfWeek.map(day => (
            <td key={day} style={{ padding: "8px", textAlign: "center", fontSize: "20px" }}>
              {isDayComplete(day) ? (
                <span
                  style={{
                    color: "#ffe342",        // Fill with yellow
                    fontWeight: "bold",     // Make it bold
                    textShadow: "0px 0px 2px black",  // Outline effect
                    WebkitTextStroke: "1px black", // Black outline
                    fontSize: "30px"
                  }}
                >
                  ★
                </span>
              ) : ""}
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
}


const Dashboard = () => {
  return (
    <div className="chart-container">
      <ScheduleTable />
      <BarChart
        width={1200} // Width larger than the container to allow scrolling
        height={300}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="pv" fill="#8884d8" />
        <Bar dataKey="uv" fill="#82ca9d" />
      </BarChart>
    </div>
  );
};

export default Dashboard;