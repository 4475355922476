
const MenuStyles = {
    menu: {
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: '#f0f0f0',
        padding: '10px 0',
        listStyle: 'none',
    },
    menuItem: {
        textDecoration: 'none',
        color: 'black',
        padding: '10px',
    },
    activeMenuItem: {
        backgroundColor: 'white',
        color: 'black',
        borderTop: 'solid black 1px',
        borderLeft: 'solid black 1px',
        borderRight: 'solid black 1px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    activeSubMenuItem: {
        backgroundColor: 'white',
        color: 'black',
        borderTop: 'solid black 1px',
        borderLeft: 'solid black 1px',
        borderRight: 'solid black 1px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
};

export default MenuStyles;